import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { useCtaRegistration } from '~hooks/useAuth';

import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import MessageBox from '~components/forms/MessageBox';

const useStyles = makeStyles({
  inputMargin: {
    marginTop: '4px'
  },
  buttonBackground: {
    backgroundColor: '#2c7abf',
    textTransform: 'none',
    padding: '11px 22px',
    boxShadow: 'unset'
  },
  tooltipDisplay: {
    display: 'block'
  },
  tooltip: {
    fontSize: '16px',
    padding: '8px'
  }
});

const OneLineSignUp = ({ isLoggedIn, isUserStateLoading }) => {
  const classes = useStyles();

  const { register, handleSubmit } = useForm();
  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, error } = regState;

  const onSubmit = ({ username }) => {
    // NOTE: MUST be one of the backend/src/models/RegistrationStrategy.ts
    registerCta({ username, strategy: 'one-line-about-page' });
  };

  return (
    <Box pt={5} width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item xs={12} md={8}>
            <TextInput
              label="Email"
              id="username-signup-form"
              name="username"
              type="email"
              inputRef={register}
              required
              margin="dense"
              disabled={isLoading}
              className={classes.inputMargin}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip
              title={
                isLoggedIn || isUserStateLoading ? 'Thanks, but you’ve already signed up!' : ''
              }
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <span className={classes.tooltipDisplay}>
                <ButtonSubmit
                  className={classes.buttonBackground}
                  disabled={isLoggedIn || isUserStateLoading}
                >
                  Sign Up
                </ButtonSubmit>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

OneLineSignUp.propTypes = {
  isLoggedIn: PropTypes.bool,
  isUserStateLoading: PropTypes.bool.isRequired
};

OneLineSignUp.defaultProps = {
  isLoggedIn: false
};

export default OneLineSignUp;
