import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import RichText from '~components/RichText';
import Link from '~components/Link';
import Container from '~components/Container';
import OneLineSignUp from './components/OneLineSignUp';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    }
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto',
    marginTop: '80px'
  },
  expansionPanel: {
    display: 'block',
    padding: '0px'
  },
  expansionSummarySpacing: {
    margin: '0px 0px',
    padding: '0px 0px'
  },
  buttonStyle: props => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '15px 52px',
    margin: '0 auto',
    marginTop: '50px',
    boxShadow: 'unset',
    backgroundColor: props.button_bg_color || '#458FCE',
    color: props.button_title_color || '#fff',
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: props.button_bg_color || '#458FCE',
      opacity: '0.8'
    }
  }),
  containerSpacing: {
    marginBottom: '100px',
    marginTop: '100px'
  }
}));

const AboutCta = ({ section_content, section_title, section_image, links }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isUserStateLoading, jwtAvailable } = session;
  return (
    <Container>
      <Box className={classes.containerSpacing}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={5}>
            <RichText
              html={section_title && section_title.html}
              mode="teamPageJobSectionTitle"
              verticalSpacing={0}
            />
            <RichText
              html={section_content && section_content.html}
              mode="teamPageFounderText"
              verticalSpacing={0}
            />
            <OneLineSignUp isLoggedIn={jwtAvailable} isUserStateLoading={isUserStateLoading} />
            <img
              src={section_image && section_image.url}
              alt={section_image && section_image.alt}
              className={classes.media}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            {links &&
              links.map((link, index) => (
                <Box
                  component={Link}
                  key={link && link.to && link.to.url}
                  bgcolor={index === 0 ? '#77C49C' : 'unset'}
                  style={{
                    padding: '23px 28px 14px 28px',
                    borderRadius: index === 0 ? '4px' : 'unset',
                    color: index !== 0 ? '#212121' : '#fff'
                  }}
                  to={link && link.to && link.to.url}
                  display="block"
                  width="100%"
                  borderBottom={
                    index !== 0 && index !== links.length - 1
                      ? '1px solid rgba(0, 0, 0, 0.2)'
                      : 'unset'
                  }
                  underline="none"
                >
                  <RichText
                    html={link && link.title && link.title.html}
                    mode="faqTopicTitle"
                    verticalSpacing={0}
                  />
                  <RichText
                    html={link && link.subtitle && link.subtitle.html}
                    mode="aboutCtaLinkSubtitle"
                    verticalSpacing={2}
                  />
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

AboutCta.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  section_content: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      subtitle: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      to: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  section_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

export default AboutCta;
